import React from 'react'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const QuestionCardMPC = ({ value, classes, questions, count, submitQuestion, RadioOnChange }) => (
  <Card className={classes.card}>
    <CardContent>
      <form onSubmit={submitQuestion}>
        <Grid alignItems="center" justify="flex-start" container spacing={0}>
          <Grid item sm={6}>
            <Typography align="center" className={classes.question} color="textPrimary" gutterBottom>
              {questions[count] && questions[count].Q} =
            </Typography>
          </Grid>
          <Grid item sm={4}>
            <RadioGroup aria-label="gender" name="anwsers" value={value} onChange={RadioOnChange}>
              <FormControlLabel
                value="0"
                control={<Radio color="primary" />}
                label={questions[count].MPQ[0]}
                labelPlacement="end"
              />
              <FormControlLabel
                value="1"
                control={<Radio color="primary" />}
                label={questions[count].MPQ[1]}
                labelPlacement="end"
              />
              <FormControlLabel
                value="2"
                control={<Radio color="primary" />}
                label={questions[count].MPQ[2]}
                labelPlacement="end"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={2}>
            <Button onClick={(e) => submitQuestion(e)} variant="contained" color="primary">
              Next
            </Button>
          </Grid>
        </Grid>
      </form>
    </CardContent>
  </Card>
)

export default QuestionCardMPC;