import { randomNumber, roundNumber } from "../general";

function mathFunctionB1() {
  const number_1 = roundNumber(randomNumber(1, 10, .02), 2)
  const number_2 = roundNumber(randomNumber(0.25, 0.75, .25), 2)

  var Question = {
    Q: `${number_1} x ${number_2}`,
    MPQ: false,
    A: roundNumber(number_1 * number_2, 3),
    P: [2, -1, -1],
    Y: 0,
    S: -1,
    T: "B1"
  }
  return Question
}

function mathFunctionB2() {
  const number_1 = roundNumber(randomNumber(.1, .9, .1), 1)
  const number_2 = roundNumber(randomNumber(.1, .9, .1), 1)

  var Question = {
    Q: `${number_1} x ${number_2}`,
    MPQ: false,
    A: roundNumber(number_1 * number_2, 2),
    P: [2, -1, -1],
    Y: 0,
    S: -1,
    T: "B2"
  }
  return Question
}

function mathFunctionB3() {
  const number_1 = roundNumber(randomNumber(0.1, 0.9, .1), 1)
  const number_2 = roundNumber(randomNumber(0.01, number_1, .01), 2)


  var Question = {
    Q: `${number_1} - ${number_2}`,
    MPQ: false,
    A: (roundNumber(number_1 - number_2, 2)),
    P: [2, -1, -1],
    Y: 0,
    S: -1,
    T: "B3"
  }
  return Question
}

function mathFunctionB4() {
  const number_1 = randomNumber(1, 10, 1)

  const power = randomNumber(1, 3, 1)
  const power_2 = randomNumber(1, 2, 1)

  var Question = {
    Q: `${number_1 / Math.pow(10, power)} / ${number_1 / Math.pow(10, power - power_2)}`,
    MPQ: false,
    A: 1 / Math.pow(10, power_2),
    P: [2, -1, -1],
    Y: 0,
    S: -1,
    T: "B4"
  }
  return Question
}

function mathFunctionB5() {
  //

  const number_1 = randomNumber(12, 99, 1)
  const number_2 = randomNumber(2, 12, 1)

  const power = randomNumber(2, 3, 1)

  var Question = {
    Q: `${number_1} x ${number_2 / Math.pow(10, power)}`,
    MPQ: false,
    A: (number_1 * number_2) / Math.pow(10, power),
    P: [2, -1, -1],
    Y: 0,
    S: -1,
    T: "B5"
  }
  return Question
}

function mathFunctionB6() {
  const number_1 = roundNumber(randomNumber(2, 100, .01), 2);
  const number_2 = roundNumber(randomNumber(1, 10, .001), 3);

  var Question = {
    Q: `${number_1} + ${number_2}`,
    MPQ: false,
    A: roundNumber(number_1 + number_2, 4),
    P: [2, -1, -1],
    Y: 0,
    S: -1,
    T: "B6"
  }
  return Question
}

function mathFunctionB7() {
  //75 x 1.03
  const number_1 = randomNumber(12, 100, 1)
  const number_2 = randomNumber(1, 1.1, .01)

  var Question = {
    Q: `${number_1} x ${number_2}`,
    MPQ: false,
    A: Math.round((number_1 * number_2) * 1000) / 1000,
    P: [2, -1, -1],
    Y: 0,
    S: -1,
    T: "B7"
  }
  return Question
}

function mathFunctionB8() {
  //5327 + 3625
  const number_1 = randomNumber(1001, 9999, 1)
  const number_2 = randomNumber(1001, 9999, 1)

  var Question = {
    Q: `${number_1} + ${number_2}`,
    MPQ: false,
    A: number_1 + number_2,
    P: [2, -1, -1],
    Y: 0,
    S: -1,
    T: "B8"
  }
  return Question
}
function mathFunctionB9() {
  //15 : 0.4
  const number_1 = randomNumber(1, 100, 1)
  const number_2 = roundNumber(randomNumber(0.1, 0.9, .1, [0.3, 0.7, 0.9]), 1)

  var Question = {
    Q: `${roundNumber(number_1 * number_2, 1)} / ${number_2}`,
    MPQ: false,
    A: number_1,
    P: [2, -1, -1],
    Y: 0,
    S: -1,
    T: "B9"
  }
  return Question
}

function mathFunctionB10() {
  // 22200 x 0.003
  const number_1 = randomNumber(12, 1000, 1)
  const number_2 = randomNumber(2, 5, 1)

  const power_1 = 2
  const power_2 = randomNumber(2, 3, 1)



  var Question = {
    Q: `${number_1 * Math.pow(10, power_1)} x ${number_2 / Math.pow(10, power_2)}`,
    MPQ: false,
    A: Math.round((number_1 * number_2) * 1000) / Math.pow(10, 3 + power_2 - power_1),
    P: [2, -1, -1],
    Y: 0,
    S: -1,
    T: "B10"
  }
  return Question
}

function mathFunctionB11() {
  // 12.06 - 7.35
  const number_1 = roundNumber(randomNumber(11, 20, .01), 2)
  const number_2 = roundNumber(randomNumber(0.01, number_1, .01), 2)

  var Question = {
    Q: `${number_1} - ${number_2}`,
    MPQ: false,
    A: roundNumber(number_1 - number_2, 2),
    P: [2, -1, -1],
    Y: 0,
    S: -1,
    T: "B11"
  }
  return Question
}

/*
function mathFunctionB12() {
    // 77,66 x 0.15
}
*/

function mathFunctionB13() {
  // 0.88 / 0.22
  const number_1 = randomNumber(1, 20, 1)
  const number_2 = roundNumber(randomNumber(0.11, 0.88, 0.11), 2)

  var Question = {
    Q: `${roundNumber(number_1 * number_2, 2)} / ${number_2}`,
    MPQ: false,
    A: number_1,
    P: [2, -1, -1],
    Y: 0,
    S: -1,
    T: "B13"
  }
  return Question
}

function mathFunctionB14() {
  // 3.03 / 0.03 
  const number_1 = randomNumber(1, 101, 1)
  const number_2 = roundNumber(randomNumber(0.01, 0.09, 0.01), 2)

  var Question = {
    Q: `${roundNumber(number_1 * number_2, 2)} / ${number_2}`,
    MPQ: false,
    A: number_1,
    P: [2, -1, -1],
    Y: 0,
    S: -1,
    T: "B14"
  }
  return Question
}

function mathFunctionB15() {
  // 0.6 / 15 = 0.04
  const number_1 = roundNumber(randomNumber(0.01, 0.15, 0.01), 2)
  const number_2 = randomNumber(5, 35, 5)

  var Question = {
    Q: `${roundNumber(number_1 * number_2, 2)} / ${number_2}`,
    MPQ: false,
    A: number_1,
    P: [2, -1, -1],
    Y: 0,
    S: -1,
    T: "B15"
  }
  return Question
}

function mathFunctionB16() {
  // 18 / 1.2

  const number_1 = roundNumber(randomNumber(1, 20, 0.5), 1)
  const number_2 = roundNumber(randomNumber(1.2, 1.8, .1), 1)



  var Question = {
    Q: `${roundNumber(number_1 * number_2, 2)} / ${number_2}`,
    MPQ: false,
    A: number_1,
    P: [2, -1, -1],
    Y: 0,
    S: -1,
    T: "B16"
  }
  return Question
}

function mathFunctionB17() {
  // 1.45 x 0.22

  const number_1 = roundNumber(randomNumber(1, 2, .01), 2)
  const number_2 = roundNumber(randomNumber(.01, 1, .01), 2)

  var Question = {
    Q: `${number_1} x ${number_2}`,
    MPQ: false,
    A: roundNumber(number_1 * number_2, 4),
    P: [2, -1, -1],
    Y: 0,
    S: -1,
    T: "B17"
  }
  return Question
}

function mathFunctionB18() {
  // -4,66 + 2,554

  const number_1 = roundNumber(randomNumber(1, 10, .01), 2)
  const number_2 = roundNumber(randomNumber(1, 5, .001), 3)

  var Question = {
    Q: `-${number_1} + ${number_2}`,
    MPQ: false,
    A: roundNumber(number_2 - number_1, 3),
    P: [2, -1, -1],
    Y: 0,
    S: -1,
    T: "B18"
  }
  return Question
}

function mathFunctionB19() {
  // 0.997 + 3.04

  const number_1 = roundNumber(randomNumber(0.001, .999, .001), 3)
  const number_2 = roundNumber(randomNumber(1, 5, .01), 2)

  var Question = {
    Q: `${number_1} + ${number_2}`,
    MPQ: false,
    A: roundNumber(number_1 + number_2, 3),
    P: [2, -1, -1],
    Y: 0,
    S: -1,
    T: "B19"
  }
  return Question
}

function mathFunctionB20() {
  // 0.6 x 0.04

  const number_1 = roundNumber(randomNumber(.2, .9, .1), 1)
  const number_2 = roundNumber(randomNumber(.2, .09, .01), 2)

  var Question = {
    Q: `${number_1} x ${number_2}`,
    MPQ: false,
    A: roundNumber(number_1 * number_2, 3),
    P: [2, -1, -1],
    Y: 0,
    S: -1,
    T: "B20"
  }
  return Question
}

function mathFunctionB21() {
  // 3.2 / 0.008 = 


  const power = randomNumber(2, 3, 1)

  const number_1 = randomNumber(100, 1000, 100)
  const number_2 = randomNumber(2, 10, 1) / Math.pow(10, power)


  var Question = {
    Q: `${roundNumber(number_1 * number_2, 1)} / ${number_2}`,
    MPQ: false,
    A: number_1,
    P: [2, -1, -1],
    Y: 0,
    S: -1,
    T: "B21"
  }
  return Question
}

function mathFunctionB22() {
  // 0.22 x 0.33


  const number_1 = randomNumber(11, 99, 11) / 100
  const number_2 = randomNumber(11, 99, 11) / 100


  var Question = {
    Q: `${number_1} x ${number_2}`,
    MPQ: false,
    A: roundNumber(number_1 * number_2, 4),
    P: [2, -1, -1],
    Y: 0,
    S: -1,
    T: "B22"
  }
  return Question
}

function mathFunctionB23() {
  // 0.012 x 40


  const number_1 = randomNumber(11, 99, 1) / 100
  const number_2 = randomNumber(10, 90, 10)


  var Question = {
    Q: `${number_1} x ${number_2}`,
    MPQ: false,
    A: roundNumber(number_1 * number_2, 4),
    P: [2, -1, -1],
    Y: 0,
    S: -1,
    T: "B23"
  }
  return Question
}

function mathFunctionB24() {
  // 5.7 x 0.12

  const number_1 = randomNumber(12, 99, 1) / 10
  const number_2 = randomNumber(12, 99, 1) / 100


  var Question = {
    Q: `${number_1} x ${number_2}`,
    MPQ: false,
    A: roundNumber(number_1 * number_2, 3),
    P: [2, -1, -1],
    Y: 0,
    S: -1,
    T: "B24"
  }
  return Question
}

function mathFunctionB25() {
  // 5.7 x 0.12


  const number_1 = randomNumber(101, 999, 1) / 1000
  const number_2 = randomNumber(11, 99, 1) / 1000


  var Question = {
    Q: `${number_1} + ${number_2}`,
    MPQ: false,
    A: roundNumber(number_1 + number_2, 3),
    P: [2, -1, -1],
    Y: 0,
    S: -1,
    T: "B25"
  }
  return Question
}

export default function randomFunctionB() {
  var functions = [
    mathFunctionB1(),
    mathFunctionB2(),
    mathFunctionB3(),
    mathFunctionB4(),
    mathFunctionB5(),
    mathFunctionB6(),
    mathFunctionB7(),
    mathFunctionB8(),
    mathFunctionB9(),
    mathFunctionB10(),
    mathFunctionB11(),
    mathFunctionB13(),
    mathFunctionB14(),
    mathFunctionB15(),
    mathFunctionB16(),
    mathFunctionB17(),
    mathFunctionB18(),
    mathFunctionB19(),
    mathFunctionB20(),
    mathFunctionB21(),
    mathFunctionB22(),
    mathFunctionB23(),
    mathFunctionB24(),
    mathFunctionB25()
  ]
  return functions[randomNumber(0, functions.length - 1, 1)]
}
