import React from 'react'
import TestNormal from './components/TestNormal';
import TestSpeed from './components/TestSpeed';


export default function Test() {
  const testType = window.location.pathname.substring(window.location.pathname.lastIndexOf('-') + 1)

  if (testType === 'normal') {
    return <TestNormal />
  } else if (testType === 'speed') {
    return <TestSpeed />
  } else {
    return <div>Oh no</div>
  }

}