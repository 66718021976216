import { randomNumber, roundNumber, shuffleArray, randomSign } from "../general";

function mathFunctionC1() {
  // 123 x 123

  const number_1 = randomNumber(112, 899, 1)
  const number_2 = randomNumber(112, 899, 1)

  let anwsers = [
    number_1 * number_2,
    number_1 * number_2 + randomSign(randomNumber(100, 9000, 100)),
    number_1 * number_2 + randomSign(randomNumber(100, 9000, 100))
  ]

  var Question = {
    Q: `${number_1} x ${number_2}`,
    MPQ: shuffleArray(anwsers),
    A: number_1 * number_2,
    P: [2, -1, -2],
    Y: 0,
    S: -1,
    T: "C1"
  }
  return Question
}

function mathFunctionC2() {
  // 12 x 123

  const number_1 = randomNumber(12, 99, 1)
  const number_2 = randomNumber(112, 999, 1)

  let anwsers = [
    number_1 * number_2,
    number_1 * number_2 + randomSign(randomNumber(10, 900, 10)),
    number_1 * number_2 + randomSign(randomNumber(10, 900, 10))
  ]

  var Question = {
    Q: `${number_1} x ${number_2}`,
    MPQ: shuffleArray(anwsers),
    A: number_1 * number_2,
    P: [2, -1, -2],
    Y: 0,
    S: -1,
    T: "C2"
  }
  return Question
}


function mathFunctionC3() {
  // 473 x 1.03

  const number_1 = randomNumber(112, 599, 1)
  const number_2 = randomNumber(1.01, 1.05, .01)

  const anwser = roundNumber(number_1 * number_2, 2)

  let anwsers = [
    anwser,
    roundNumber(anwser + randomSign(randomNumber(.1, 2, .1)), 2),
    roundNumber(anwser + randomSign(randomNumber(.1, 2, .1)), 2),
  ]

  var Question = {
    Q: `${number_1} x ${number_2}`,
    MPQ: shuffleArray(anwsers),
    A: anwser,
    P: [2, -1, -2],
    Y: 0,
    S: -1,
    T: "C3"
  }
  return Question
}

function mathFunctionC4() {
  // 1010 x 2020

  let number_1 = randomNumber(1000, 5000, 1000);
  number_1 = number_1 * 1.01
  let number_2 = randomNumber(1000, 5000, 1000);
  number_2 = number_2 * 1.01

  let anwsers = [
    number_1 * number_2,
    number_1 * number_2 + randomSign(randomNumber(10000, 50000, 10000)),
    number_1 * number_2 + randomSign(randomNumber(10000, 50000, 10000))
  ]


  var Question = {
    Q: `${number_1} x ${number_2}`,
    MPQ: shuffleArray(anwsers),
    A: number_1 * number_2,
    P: [2, -1, -2],
    Y: 0,
    S: -1,
    T: "C4"
  }
  return Question
}

function mathFunctionC5() {
  // 5214 : 6

  let number_1 = randomNumber(1, 9, 1);
  let number_2 = randomNumber(121, 1000, 1);

  let anwser = number_1 * number_2;

  let anwsers = [
    number_2,
    number_2 + randomSign(randomNumber(1, 19, 1)),
    number_2 + randomSign(randomNumber(1, 19, 1))
  ]


  var Question = {
    Q: `${anwser} / ${number_1}`,
    MPQ: shuffleArray(anwsers),
    A: number_2,
    P: [2, -1, -2],
    Y: 0,
    S: -1,
    T: "C5"
  }
  return Question
}

function mathFunctionC6() {
  // 47.3 x 1.004

  const number_1 = roundNumber(randomNumber(11.2, 99.9, .1), 1)
  const number_2 = roundNumber(randomNumber(1.001, 1.005, .001), 3)

  const anwser = roundNumber(number_1 * number_2, 4)

  let anwsers = [
    anwser,
    roundNumber(anwser + randomSign(randomNumber(.01, .9, .1)), 4),
    roundNumber(anwser + randomSign(randomNumber(.01, .9, .1)), 4),
  ]

  var Question = {
    Q: `${number_1} x ${number_2}`,
    MPQ: shuffleArray(anwsers),
    A: anwser,
    P: [2, -1, -2],
    Y: 0,
    S: -1,
    T: "C6"
  }
  return Question
}

function mathFunctionC7() {
  // 473 x 0.15

  const number_1 = randomNumber(1111, 5999, 1)
  const number_2 = roundNumber(randomNumber(0.05, 0.25, .05), 2)

  const anwser = roundNumber(number_1 * number_2, 2)

  let anwsers = [
    anwser,
    roundNumber(anwser + randomSign(1), 2),
    roundNumber(anwser + randomSign(randomNumber(.1, .3, .1)), 2),
  ]

  var Question = {
    Q: `${number_1} x ${number_2}`,
    MPQ: shuffleArray(anwsers),
    A: anwser,
    P: [2, -1, -2],
    Y: 0,
    S: -1,
    T: "C7"
  }
  return Question
}

function mathFunctionC8() {
  // 123 x 0.123

  const number_1 = randomNumber(115, 895, 10) + 5
  const number_2 = number_1;

  let anwsers = [
    (number_1 * number_2 / 1000),
    (number_1 * number_2 + randomSign(randomNumber(100, 9000, 100))) / 1000,
    (number_1 * number_2 + randomSign(randomNumber(100, 9000, 100))) / 1000
  ]

  var Question = {
    Q: `${number_1} x ${number_2 / 1000}`,
    MPQ: shuffleArray(anwsers),
    A: number_1 * number_2 / 1000,
    P: [2, -1, -2],
    Y: 0,
    S: -1,
    T: "C8"
  }
  return Question
}


export default function randomFunctionC() {
  var functions = [
    mathFunctionC1(),
    mathFunctionC2(),
    mathFunctionC3(),
    mathFunctionC4(),
    mathFunctionC5(),
    mathFunctionC6(),
    mathFunctionC7(),
    mathFunctionC8()
  ]
  return functions[randomNumber(0, functions.length - 1, 1)]
}