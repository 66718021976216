import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import {
  useFirestoreConnect,
  isLoaded
} from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import LoadingSpinner from 'components/LoadingSpinner'
import styles from './ScoreTable.styles';
import Moment from 'moment'


const useStyles = makeStyles(styles)

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props;

  const classes = useStyles()
  const theme = useTheme();

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div >
  );
}

function useScoreList(exerciseId, period) {

  useFirestoreConnect([
    {
      collection: `leaderboards${period}`,
      doc: exerciseId
    }
  ])
  // Get projects from redux state
  const leaderboards = useSelector(state => state.firestore.data['leaderboards' + period])

  let leaderboard = []


  if (isLoaded(leaderboards)) {
    if (leaderboards[exerciseId]) {
      leaderboard = leaderboards[exerciseId].scores.map(score => {
        return {
          username: score.username,
          score: score.score,
          seconds: score.timeTakenInSeconds,
          date: score.date.seconds
        }
      })
    }
  }


  return { leaderboards, leaderboard }
}


const ScoreTable = ({ exerciseId, period }) => {
  const {
    leaderboards,
    leaderboard
  } = useScoreList(exerciseId, period)
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, leaderboard.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const classes = useStyles()


  if (!isLoaded(leaderboards)) {
    return <LoadingSpinner />
  }
  return (
    <TableContainer>
      <Table aria-label="custom pagination table">
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell align="left">Username</TableCell>
            <TableCell align="right">Score</TableCell>
            <TableCell align="right">Seconds</TableCell>
            <TableCell align="right">Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? leaderboard.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : leaderboard
          ).map((score, i) => (
            <TableRow key={'leaderboard-' + i}>
              <TableCell component="th" scope="row">
                {i + 1}
              </TableCell>
              <TableCell align="left">{score.username}</TableCell>
              <TableCell align="right">{score.score}</TableCell>
              <TableCell align="right">{score.seconds}</TableCell>
              <TableCell align="right">{Moment(score.date * 1000).format('DD-MM-YY')}</TableCell>
            </TableRow>
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={3}
              count={leaderboard.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

export default ScoreTable;
