import React from 'react'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Results from '../Results'
import { randomFunctionA, randomFunctionB, randomFunctionC } from '../../../../MathFunctions';
import { makeStyles } from '@material-ui/core/styles';
import styles from './TestNormal.styles';
import {
  useFirestore,
} from 'react-redux-firebase';
import useNotifications from 'modules/notification/useNotifications';
import { useSelector } from 'react-redux'
import QuestionCardNormal from '../components/QuestionCardNormal';
import QuestionCardMPC from '../components/QuestionCardMPC';
import LastQuestion from '../components/LastQuestion';
import { useMediaQuery } from 'react-responsive'


function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

function useFunctionsScore(initSeconds, exerciseId) {

  const { showSuccess, showError } = useNotifications()
  const firestore = useFirestore()
  const auth = useSelector(state => state.firebase.auth)
  const [scoreSubmitted, setScoreSubmitted] = React.useState(false);

  function submitScore(score, seconds) {
    if (!auth.uid) {
      return showError('You must be logged in to add your highscore')
    } else if (scoreSubmitted === true) {
      return showError('Score already submitted');
    }
    return firestore
      .add('scores', {
        uid: auth.uid,
        date: firestore.FieldValue.serverTimestamp(),
        score,
        timeTakenInSeconds: initSeconds - seconds,
        exerciseId,

      })
      .then(() => {
        setScoreSubmitted(true);
        showSuccess('High score added successfully');
      })
      .catch(err => {
        console.error('Error:', err) // eslint-disable-line no-console
        showError(err.message || 'Could not add High Score')
        return Promise.reject(err)
      })
  }
  return { submitScore }
}


function useFunctionsTestNormal() {

  const [questions, setQuestions] = React.useState([])
  const [count, setCount] = React.useState(0)
  const [anwser, setAnwser] = React.useState('')
  const [Finished, setFinished] = React.useState(false)
  const [score, setScore] = React.useState(0)
  const [value, setValue] = React.useState(0)
  const [maxCount, setMaxCount] = React.useState(120)
  const [inProgress, setInProgress] = React.useState(true)
  const [seconds, setSeconds] = React.useState(120);
  const [initSeconds, setInitSeconds] = React.useState(120);

  const exerciseId = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)


  function onChangeAnwser(e) {
    setAnwser(e.target.value)
  }

  function changeCount(e, x) {
    setCount(x);
    setFinished(false);
  }

  function RadioOnChange(e) {
    setValue(e.target.value);
    setAnwser(questions[count].MPQ[e.target.value]);
  }

  function stopTest() {

    let score = 0

    questions.map(question => {
      score += question.S
      return null
    })
    setScore(score);
    setInProgress(false);
  }

  function submitQuestion(e) {
    e.preventDefault();
    questions[count].Y = Number(anwser)
    if (Number(anwser) === questions[count].A) {
      e.target.reset && e.target.reset();
      questions[count].S = questions[count].P[0];
      setFinished(count === maxCount ? true : false)
      setCount(count === maxCount ? maxCount : count + 1)
    } else {
      e.target.reset && e.target.reset();
      questions[count].S = questions[count].P[2];
      setFinished(count === maxCount ? true : false)
      setCount(count === maxCount ? maxCount : count + 1)
    }
    if (value) {
      setValue('')
    }
  }

  function startQuiz() {
    let questions = [];
    let time;
    let maxCount;
    switch (exerciseId) {
      case 'utr8-a-normal':
        maxCount = 29;
        for (let i = 0; i <= maxCount; i++) {
          questions.push(randomFunctionA())
        }
        time = 120;
        break;
      case "utr8-b-normal":
        maxCount = 29;
        for (let i = 0; i <= maxCount; i++) {
          questions.push(randomFunctionB())
        };
        time = 240;
        break;
      case "utr8-c-normal":
        maxCount = 14;
        for (let i = 0; i <= maxCount; i++) {
          questions.push(randomFunctionC())
        };
        time = 240;
        break;
      case "utr8-all-normal":
        maxCount = 75;
        for (let i = 0; i < 30; i++) {
          questions.push(randomFunctionA())
        };
        for (let i = 0; i < 30; i++) {
          questions.push(randomFunctionB())
        };
        for (let i = 0; i < 15; i++) {
          questions.push(randomFunctionC())
        };
        time = 600;
        break;
      default:
    }
    setInitSeconds(time);
    setSeconds(time);
    setQuestions(questions);
    setMaxCount(maxCount);
    setInProgress(true);
  }

  React.useEffect(() => {
    startQuiz();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    let interval = null;
    if (inProgress && seconds > 0) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      stopTest();
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgress, seconds]);

  return {
    changeCount,
    RadioOnChange,
    stopTest,
    submitQuestion,
    count,
    questions,
    seconds,
    inProgress,
    Finished,
    value,
    onChangeAnwser,
    score,
    initSeconds,
    exerciseId
  }
}

const useStyles = makeStyles(styles)

const TestNormal = () => {
  const classes = useStyles()

  const {
    changeCount,
    RadioOnChange,
    stopTest,
    submitQuestion,
    count,
    questions,
    seconds,
    inProgress,
    Finished,
    value,
    onChangeAnwser,
    score,
    initSeconds,
    exerciseId
  } = useFunctionsTestNormal();

  const {
    submitScore
  } = useFunctionsScore(initSeconds, exerciseId);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 700px)' })

  return (
    inProgress ?
      <Container className={classes.root} maxWidth="lg">
        <Grid container spacing={3} direction={isTabletOrMobile ? "column-reverse" : "row"}>
          <Grid item xs={12} md={3}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={count}
              onChange={changeCount}
              aria-label="Vertical tabs example"
              className={classes.tabs}
            >
              {questions.map((Question, i) => {
                return (
                  <Tab key={'tab-' + i} label={Question.Q} {...a11yProps(0)} />
                )
              })}
            </Tabs>
          </Grid>
          {/**  column 2 */}
          <Grid item xs={12} md={6}>
            {/** Question Card */}
            {questions[count] && questions[count].MPQ && !Finished &&
              <QuestionCardMPC count={count} value={value} RadioOnChange={RadioOnChange} submitQuestion={submitQuestion} classes={classes} questions={questions} />
            }
            {questions[count] && !questions[count].MPQ && !Finished &&
              <QuestionCardNormal onChangeAnwser={onChangeAnwser} submitQuestion={submitQuestion} count={count} classes={classes} questions={questions} />
            }
            {Finished &&
              <LastQuestion classes={classes} />
            }

          </Grid>

          {/**  column 3 */}
          <Grid item xs={12} md={3}>
            <Card className={classes.card}>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Vraag {count + 1}
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                  Time: {seconds}
                </Typography>
              </CardContent>
              <CardActions>
                <Button color="primary" onClick={() => stopTest()} size="small">Stop</Button>
              </CardActions>
            </Card>
          </Grid>

        </Grid>
      </Container>

      :
      <Results exerciseId={exerciseId} submitScore={submitScore} questions={questions} score={score} seconds={seconds} />
  )
}

export default TestNormal
