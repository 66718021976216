/**
 * NOTE: This file is ignored from git tracking. In a CI environment it is
 * generated by firebase-ci based on config in .firebaserc (see .gitlab-ci.yaml).
 * This is done so that environment specific settings can be applied.
 */

export const env = 'dev'

// Config for firebase
export const firebase = {
  apiKey: "AIzaSyCo7zPm_M_-Kpa7xh1CYabdjGgco2WmTaM",
  authDomain: "mennohuijbregts-83fa5.firebaseapp.com",
  databaseURL: "https://mennohuijbregts-83fa5.firebaseio.com",
  projectId: "mennohuijbregts-83fa5",
  storageBucket: "mennohuijbregts-83fa5.appspot.com",
  messagingSenderId: "898091380840",
  appId: "1:898091380840:web:eb60e83d4cd9b2981e8bdd"
};

// Config to override default reduxFirebase config in store/createStore
// which is not environment specific.
// For more details, visit http://react-redux-firebase.com/docs/api/enhancer.html
export const reduxFirebase = {
  enableLogging: false, // enable/disable Firebase Database Logging
}

export default {
  env,
  firebase,
  reduxFirebase
}
