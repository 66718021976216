export default theme => ({
    root: {
        ...theme.flexColumnCenter,
        marginTop: "50px"
    },
    paperTrue: {
        maxWidth: 400,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
        background: "linear-gradient(to right, #2E8B57 20%, white 0%);",
    },
    paperFalse: {
        maxWidth: 400,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
        background: "linear-gradient(to right, #F08080 20%, white 0%);",

    },
    iconCorrect: {
        fontSize: "45px",
        color: "green"
    },
    iconWrong: {
        fontSize: "45px",
        color: "red"
    },
    scoreRow: {
        marginBottom: "50px"
    },
    media: {
        height: 170,
    }
})