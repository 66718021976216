export default theme => ({
  root: {
    ...theme.flexColumnCenter,
    marginTop: "50px",
  },
  section: {
    ...theme.flexColumnCenter
  },
  questionPaper: {
    padding: "10px 0",
    margin: '4px',
    textAlign: "center",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    maxHeight: 448
  },
  card: {
    minWidth: 275,
  },
  question: {
    fontSize: "25px"
  }

})
