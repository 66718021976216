import React from 'react';
import ScoreTabs from 'modules/ranking/ScoreTabs';
import Grid from '@material-ui/core/Grid';
import styles from './Ranking.styles';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';



const useStyles = makeStyles(styles)
export default function Ranking() {

  const classes = useStyles()
  const exerciseId = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item md={6} xs={12}>
        <Typography gutterBottom variant="h5" component="h2">
          Normal
        </Typography>
        <ScoreTabs exerciseId={exerciseId + '-normal'} />
      </Grid>
      <Grid item md={6} xs={12}>
        <Typography gutterBottom variant="h5" component="h2">
          Speed
        </Typography>
        <ScoreTabs exerciseId={exerciseId + '-speed'} />
      </Grid>
    </Grid>
  )
}