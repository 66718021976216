import React from 'react'
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';


const QuestionNormal = ({ classes, questions, count, onChangeAnwser, submitQuestion }) => (
  <Card className={classes.card}>
    <CardContent>
      <Grid alignItems="center" justify="space-between" container spacing={0}>
        <Grid item sm={6}>
          <Typography align="center" className={classes.question} color="textPrimary" gutterBottom>
            {questions[count] && questions[count].Q} =
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <form onSubmit={submitQuestion}>
            <TextField
              autoFocus={true}
              onChange={e => onChangeAnwser(e)}
              id="outlined-basic"
              InputLabelProps={{
                shrink: true,
              }}
              label="Anwser"
              variant="outlined"
              step="any"
            />
          </form>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
)

export default QuestionNormal