export default theme => ({
  root: {
    ...theme.flexColumnCenter,
    marginTop: "35px"
  },
  section: {
    ...theme.flexColumnCenter
  },
  card: {
    marginBottom: "20px;"
  }
})
