import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/container'
import styles from './HomePage.styles'
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

let tests = [
  {
    title: "UTR8 - Part 1",
    info: "30 Questions - 120 seconds",
    des: "Improve your mental math skills by practicing our arithmetic speed drills.",
    linkNormal: "utr8-a-normal",
    linkSpeed: "utr8-a-speed",
    linkRanking: "utr8-a"
  },
  {
    title: "UTR8 - Part 2",
    info: "30 Questions - 240 seconds",
    des: "Improve your mental math skills by practicing our arithmetic speed drills.",
    linkNormal: "utr8-b-normal",
    linkSpeed: "utr8-b-speed",
    linkRanking: "utr8-b"

  },
  {
    title: "UTR8 - Part 3",
    info: "30 Questions - 240 seconds",
    des: "Improve your mental math skills by practicing our arithmetic speed drills.",
    linkNormal: "utr8-c-normal",
    linkRanking: "utr8-c"
  },
  {
    title: "UTR8 - All",
    info: "75 Questions - 600 seconds",
    des: "Improve your mental math skills by practicing our arithmetic speed drills.",
    linkNormal: "utr8-all-normal",
    linkRanking: "utr8-all"
  }

]


const TestCard = ({ classes, test }) => {
  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {test.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {test.info}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {test.des}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        {test.linkNormal &&
          <Link href={'test/' + test.linkNormal}>
            <Button size="small" color="primary">
              Normal
          </Button>
          </Link>
        }
        {test.linkSpeed &&
          <Link href={'test/' + test.linkSpeed}>
            <Button size="small" color="primary">
              Speed
          </Button>
          </Link>
        }
        {test.linkRanking &&
          <Link href={'ranking/' + test.linkRanking}>
            <Button size="small" color="primary">
              Ranking
          </Button>
          </Link>
        }
      </CardActions>
    </Card>
  )
}




const useStyles = makeStyles(styles)

function Home() {
  const classes = useStyles()

  return (
    <Container className={classes.root}>
      <Grid container>
        <Grid item xs={12} md={6}>
          {tests.map((test, i) => (
            <TestCard key={"TestCard-" + i} test={test} classes={classes} />

          ))
          }
        </Grid>
        <Grid item xs={6}>

        </Grid>
      </Grid>


    </Container>
  )
}

export default Home
