import React from 'react'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import styles from './Results.styles';
import Button from '@material-ui/core/Button';
import ScoreTabs from 'modules/ranking/ScoreTabs';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux'
import mathImage from 'static/images/math.jpeg';

const AnwserCardGood = ({ questions, classes, i }) => (
  <Paper className={classes.paperTrue}>
    <Grid container wrap="nowrap" spacing={2}>
      <Grid item sm={2}>
        <Box align="center" fontSize={23} fontWeight={200}>{i + 1}</Box>
      </Grid>
      <Grid item sm={1}></Grid>
      <Grid item sm={9}>
        <Box textAlign="left">{questions.Q} = {questions.Y}</Box>
      </Grid>
    </Grid>
  </Paper >
)

const AnwserCardFalse = ({ questions, classes, i }) => (
  <Paper className={classes.paperFalse}>
    <Grid container wrap="nowrap" spacing={2}>
      <Grid item sm={2}>
        <Box align="center" fontSize={23} fontWeight={200}>{i + 1}</Box>
      </Grid>
      <Grid item sm={1}></Grid>
      <Grid item sm={9}>
        <Box textAlign="left">{questions.Q} ≠ {questions.Y}</Box>
        <Box textAlign="left">Correct anwser is: {questions.A}</Box>
      </Grid>
    </Grid>
  </Paper >
)

const ResultsPaper = ({ classes, score, seconds, submitScore }) => {


  const username = useSelector(state => state.firebase.profile.username)
  return (
    <Card>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={mathImage}
          title="Mental math"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Good job{username && " " + username}!
          </Typography>
          <Typography variant="body1" color="textSecondary" component="p">
            Score: {score}
          </Typography>
          <Typography variant="body1" color="textSecondary" component="p">
            Seconds left: {seconds}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button onClick={() => submitScore(score, seconds)} size="small" color="primary">
          Save score
        </Button>
        <a href=" ">
          <Button size="small" color="primary">
            Try again
        </Button>
        </a>
      </CardActions>
    </Card>
  )
}


const useStyles = makeStyles(styles)

const Results = ({ submitScore, questions, score, seconds, exerciseId }) => {
  const classes = useStyles();
  return (
    <Container className={classes.root} maxWidth="lg">
      {/** row 1 */}
      <Grid className={classes.scoreRow} container spacing={2}>
        <Grid item xs={12} md={6}>
          <ResultsPaper classes={classes} score={score} seconds={seconds} submitScore={submitScore} />
        </Grid>
        <Grid item xs={12} md={6}>
          <ScoreTabs exerciseId={exerciseId} />
        </Grid>
      </Grid>
      {/** row 2 */}
      <Grid container spacing={1}>
        {questions.map((questions, i) => (
          <Grid xs={12} sm={6} md={3} item key={"anwser-card-" + i}>
            {questions.A === questions.Y ? <AnwserCardGood i={i} classes={classes} questions={questions} /> : <AnwserCardFalse i={i} classes={classes} questions={questions} />}
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default Results
