export function randomNumber(start, end, step, exception) {
  let exception_ = []

  if (exception) {
    exception_ = exception
  }

  let randomNumber = (Math.random() * (end - start)) + start
  let roundNumber = Math.round(randomNumber / step)
  let number = roundNumber * step

  while (exception_.includes(number)) {
    randomNumber = (Math.random() * (end - start)) + start
    roundNumber = Math.round(randomNumber / step)
    number = roundNumber / Math.round(1 / step)
  }

  return number
}


export function roundNumber(number, decimals) {

  const newNumber = Math.round(number * Math.pow(10, decimals)) / Math.pow(10, decimals)

  return newNumber
}

export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array
}


export function randomSign(number) {
  const random = Math.random()
  if (random > 0.5) {
    return number
  } else {
    return number * -1
  }
}