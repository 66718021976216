import { randomNumber } from "../general";


function mathFunctionA1() {
  const number_1 = randomNumber(12, 100, 1)
  const number_2 = randomNumber(2, 12, 1)

  var Question = {
    Q: `${number_1} x ${number_2}`,
    MPQ: false,
    A: number_1 * number_2,
    P: [1, -2, -3],
    Y: 0,
    S: -2,
    T: "A1"
  }
  return Question
}

function mathFunctionA2() {
  const number_1 = randomNumber(12, 100, 1)
  const number_2 = randomNumber(2, 12, 1)

  var Question = {
    Q: `${number_1 * number_2} / ${number_2}`,
    MPQ: false,
    A: number_1,
    P: [1, -2, -3],
    Y: 0,
    S: -2,
    T: "A2"

  }
  return Question
}


function mathFunctionA3() {
  const number_1 = randomNumber(2, 200, 1)
  const number_2 = randomNumber(2, 150, 1)

  var Question = {
    Q: `${number_1} + ${number_2}`,
    MPQ: false,
    A: number_1 + number_2,
    P: [1, -2, -3],
    Y: 0,
    S: -2,
    T: "A3"

  }
  return Question
}

function mathFunctionA4() {
  const number_1 = randomNumber(2, 100, 1)
  const number_2 = randomNumber(2, 200, 1)

  var Question = {
    Q: `${number_1 + number_2} - ${number_1}`,
    MPQ: false,
    A: number_2,
    P: [1, -2, -3],
    Y: 0,
    S: -2,
    T: "A4"
  }
  return Question
}

export default function randomFunctionA() {
  var functions = [
    mathFunctionA1(),
    mathFunctionA2(),
    mathFunctionA3(),
    mathFunctionA4()
  ]
  return functions[randomNumber(0, functions.length - 1, 1)]
}