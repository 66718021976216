import React from 'react'
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';


const LastQuestion = ({ classes }) => (
  <Card className={classes.card}>
    <CardContent>
      <Typography align="center" className={classes.question} color="textPrimary" gutterBottom>
        This was de last question!
        </Typography>
    </CardContent>
  </Card>
)

export default LastQuestion